const SingleBanner = () => {
  return (
    <section className="single-banner">
      <a href="https://www.decathlon.com.br/collection/?q=729&O=OrderByTopSaleDESC#1">
        <img
          src="https://decathlonpro.vteximg.com.br/arquivos/bike-criancas-desk-lp-2022.png"
          alt="Dia das Crianças | Decatlhon"
          className="desk"
        />
        <img
          src="https://decathlonpro.vteximg.com.br/arquivos/bike-criancas-mobile-lp-2022.png"
          alt="Dia das Crianças | Decatlhon"
          className="mob"
        />
      </a>
    </section>
  )
}

export default SingleBanner
