const ThreeCards = () => {
  return (
    <section className="three-banners">
      <a href="https://www.decathlon.com.br/collection/?q=731&O=OrderByTopSaleDESC#1">
        <img
          src="https://decathlonpro.vteximg.com.br/arquivos/patins-criancas-lp-2022.png"
          alt="Patins e Skate | Decathlon"
        />
      </a>
      <a href="https://www.decathlon.com.br/collection/?q=730&O=OrderByTopSaleDESC#1">
        <img
          src="https://decathlonpro.vteximg.com.br/arquivos/patinetes-criancas-lp-2022.png"
          alt="Patinete | Decathlon"
        />
      </a>
      <a href="https://www.decathlon.com.br/collection/?q=732&O=OrderByTopSaleDESC#1">
        <img
          src="https://decathlonpro.vteximg.com.br/arquivos/protecao-criancas-lp-2022.png"
          alt="Equipamentos | Decathlon"
        />
      </a>
    </section>
  )
}

export default ThreeCards
