const ButtonsGender = () => {
  return (
    <section className="buttons-genders">
      <a href="https://www.decathlon.com.br/collection/?q=734&O=OrderByTopSaleDESC#1">
        vestuário
      </a>
      <a href="https://www.decathlon.com.br/collection/?q=735&O=OrderByTopSaleDESC#1">
        basquete
      </a>
      <a href="https://www.decathlon.com.br/collection/?q=736&O=OrderByTopSaleDESC#1">
        badminton
      </a>
      <a href="https://www.decathlon.com.br/collection/?q=744&O=OrderByTopSaleDESC#1">
        raquetes
      </a>
      <a href="https://www.decathlon.com.br/pesquisa/?q=futebol&filter=discrete:2400:2401&filter=discrete:2400:4810&filter=discrete:2400:4808&filter=discrete:77560:77654&page=1">
        futebol
      </a>
    </section>
  )
}

export default ButtonsGender
