import BannerFull from './BannerFull'
import Blog from './Blog'
import ButtonsGender from './ButtonsGender'
import './styles.scss'
import ThreeCards from './ThreeCards'
import SingleBanner from './SingleBanner'
import TwoBanners from './TwoBanners'
import PriceGifts from './PriceGifts'

const bannersTop = [
  {
    link: 'https://www.decathlon.com.br/collection/?q=733&O=OrderByTopSaleDESC#1',
    image:
      'https://decathlonpro.vteximg.com.br/arquivos/agua-criancas-lp-2022.png',
    alt: 'Brincar na Água | Decathlon',
  },
  {
    link: 'https://www.decathlon.com.br/collection/?q=743&O=OrderByTopSaleDESC#1',
    image:
      'https://decathlonpro.vteximg.com.br/arquivos/divertir-criancas-lp-2022.png',
    alt: 'Divertir com os amigos | Decathlon',
  },
]

const bannersMiddle = [
  {
    link: 'https://www.decathlon.com.br/collection/?q=741&O=OrderByTopSaleDESC#1',
    image:
      'https://decathlonpro.vteximg.com.br/arquivos/bolas-criancas-lp-2022.png',
    alt: 'Bola para esporte | Decathlon',
  },
  {
    link: 'https://www.decathlon.com.br/collection/?q=742&O=OrderByTopSaleDESC#1',
    image:
      'https://decathlonpro.vteximg.com.br/arquivos/traves-criancas-lp-2022.png',
    alt: 'Traves, tabelas e redes | Decathlon',
  },
]

const bannersBottom = [
  {
    link: 'https://www.decathlon.com.br/circula',
    image:
      'https://decathlonpro.vteximg.com.br/arquivos/circula-criancas-lp-2022.png',
    alt: 'Circula | Decathlon',
  },
  {
    link: 'https://www.decathlon.com.br/collection/?q=599&O=OrderByTopSaleDESC#1',
    image:
      'https://decathlonpro.vteximg.com.br/arquivos/banner-itau-criancas-2022.png',
    alt: 'Cartão | Decathlon',
  },
]

const DiaDasCriancasComponent = () => {
  return (
    <>
      <main className="main-dia-das-criancas-2022">
        <BannerFull />
        <ButtonsGender />
        <SingleBanner />
        <ThreeCards />
        <TwoBanners banners={bannersTop} />
        <PriceGifts />
        <TwoBanners banners={bannersMiddle} />
        <TwoBanners banners={bannersBottom} spacement="spacement" />
        <Blog />
      </main>
    </>
  )
}

export default DiaDasCriancasComponent
