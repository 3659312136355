const BannerFull = () => {
  return (
    <section className="banner-full">
      <img
        className="desk"
        src="https://decathlonpro.vteximg.com.br/arquivos/geral-criancas-lp-2022.png"
        alt="Banner Dia das Crianças | Decathlon"
      />
      <img
        className="mob"
        src="https://decathlonpro.vteximg.com.br/arquivos/geral-criancas-mobile-lp-2022.png"
        alt="Banner Dia das Crianças | Decathlon"
      />
    </section>
  )
}

export default BannerFull
