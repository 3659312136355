const PriceGifts = () => {
  return (
    <section className="price-gifts">
      <h2>presentes por preço</h2>
      <div className="prices-wrapper">
        <a href="https://www.decathlon.com.br/collection/?q=737&O=OrderByTopSaleDESC#1">
          até r$49,99
        </a>
        <a href="https://www.decathlon.com.br/collection/?q=738&O=OrderByTopSaleDESC#1">
          até r$79,99
        </a>
        <a href="https://www.decathlon.com.br/collection/?q=739&O=OrderByTopSaleDESC#1">
          até r$129,99
        </a>
        <a href="https://www.decathlon.com.br/collection/?q=740&O=OrderByTopSaleDESC#1">
          até r$199,99
        </a>
      </div>
    </section>
  )
}

export default PriceGifts
