interface TwoCardsProps {
  banners: Array<{
    link: string
    image: string
    alt: string
  }>
  spacement?: string
}

const TwoBanners = ({ banners, spacement }: TwoCardsProps) => {
  return (
    <section className={`two-banners ${spacement}`}>
      {banners.map((banner, index) => (
        <a key={index} href={banner.link}>
          <img src={banner.image} alt={banner.alt} />
        </a>
      ))}
    </section>
  )
}

export default TwoBanners
