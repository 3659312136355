import { Swiper, SwiperSlide } from 'swiper/react'
import { Lazy, Navigation, Pagination } from 'swiper'

const Blog = () => {
  return (
    <section className="blog">
      <div className="av-container">
        <div className="av-row">
          <div className="section-title">
            <h2 className="section-title__text">DICAS EM DESTAQUES</h2>
          </div>
          <p className="texto-blog">
            Se você é apaixonado por esportes igual a gente, fique de olho nas
            dicas e conselhos que nossos esportistas prepararam pra te ajudar a
            praticar seus esportes favoritos
          </p>
        </div>
      </div>
      <div className="av-container">
        <div className="av-row">
          <div className="section08-blog-post-content">
            <Swiper
              className="slick-slide-swiper-inverno"
              slidesPerView={1}
              pagination={{ clickable: true }}
              breakpoints={{
                1244: {
                  slidesPerView: 3,
                },
                1024: {
                  slidesPerView: 3,
                },
                1100: {
                  slidesPerView: 3,
                },
                768: {
                  slidesPerView: 3,
                },
                425: {
                  slidesPerView: 1,
                },
                320: {
                  slidesPerView: 1,
                },
              }}
              lazy={{
                loadPrevNext: true,
                loadPrevNextAmount: 1,
              }}
              modules={[Navigation, Lazy, Pagination]}
            >
              <SwiperSlide>
                <div className="post">
                  <a href="https://souesportista.decathlon.com.br/esporte-para-criancas-com-que-idade-elas-podem-comecar/">
                    <img
                      src="https://decathlonpro.vteximg.com.br/arquivos/20210915-blog-lp-dia-das-crianças-01.png"
                      alt="dia das crianças | Decathlon"
                    />
                    <div className="texto-post">
                      <p className="titulo-post">
                        ESPORTE PARA CRIANÇAS: COM QUE IDADE ELAS PODEM COMEÇAR?
                      </p>
                      <p className="conteudo-post">
                        Descubra com qual idade é melhor iniciar seu filho no
                        esporte e quais esportes praticar!
                      </p>
                    </div>
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="post">
                  <a href="https://souesportista.decathlon.com.br/os-beneficios-do-esporte-para-as-criancas/">
                    <img
                      src="https://decathlonpro.vteximg.com.br/arquivos/20210915-blog-lp-dia-das-crianças-02.png"
                      alt="dia das crianças | Decathlon"
                    />
                    <div className="texto-post">
                      <p className="titulo-post">
                        OS BENEFÍCIOS DO ESPORTE PARA AS CRIANÇAS
                      </p>
                      <p className="conteudo-post">
                        Você sabe que o esporte é bom para a saúde, mas você
                        conhece os benefícios da atividade esportiva para as
                        crianças?
                      </p>
                    </div>
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="post">
                  <a href="https://souesportista.decathlon.com.br/como-escolher-o-capacete-de-ciclismo-do-seu-filhoa/">
                    <img
                      src="https://decathlonpro.vteximg.com.br/arquivos/20210915-blog-lp-dia-das-crianças-03.png"
                      alt="Saber Mais | Decathlon"
                    />
                    <div className="texto-post">
                      <p className="titulo-post">
                        COMO ESCOLHER O CAPACETE DE CICLISMO DO SEU FILHO(A)?
                      </p>
                      <p className="conteudo-post">
                        Você sabia que o capacete infantil de ciclismo é
                        obrigatório, tanto para passageiros como para
                        condutores? Saiba como escolher
                      </p>
                    </div>
                  </a>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Blog
